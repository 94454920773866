.photo-container {
    position: relative;
    text-align: center;
  }
  
  .photo-credit {
    text-align:center;
    bottom: 10px;
    left: 10px;
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
  }
  
  .photo-credit a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
  }
  
  /* Add more styling as necessary */
  