:root {
  --background-color: #ffffff; /* Dark background for the full page */
  --text-color: #ffffff; /* White text for contrast */
  --accent-color: #6f3ce8; /* A vibrant accent color for buttons and highlights */
  --cell-color: #1f65ab; /* Light color for the grid cells */
  --cell-border-color: #A7C7E7; /* Accent color for the grid cell borders */
  --grid-size:4;
}

body, html {
  height: 100%;
  margin: 0;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.gameContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top:0;
  height: 90vh;
  box-sizing: border-box;
  }

  .weeklytheme{
    font-size:large;
    margin-bottom: 10px;
  }

  .scoreAndHelpAndGridSizeLabel {
    display: flex;
    width:400px;
    justify-content: space-around;
    align-items: center;
    padding: 1px 0; /* Adds padding above and below the items */
   /* Light background for the container */
    border-radius: 5px;
    /* box-shadow: 0 2px 5px rgba(0,0,0,0.2); */
    margin-bottom: 10px;
  }
  .score {
    font-size: 0.8em; /* Larger text for score */
    font-weight: bold; /* Make the score text bold */
    margin-right: 20px; /* Spacing to the right of the score */
  }

  .grid-size-label {
    font-size: 0.8em;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2px 2px 2px 2px;
    
  }
  .grid-size-label select {
    position: relative;
    font-size: 0.8em;
    padding: 5px 10px; /* Padding inside the select box */
    border-radius: 5px; /* Rounds the corners of the select box */
    cursor: pointer; /* Changes the cursor to indicate it's selectable */
    border-color: #f2f2f2;
  }


  #custom-tooltip {
    position: absolute;
    left: 50%;
    transform: translateX(-20%);
    transform: translateY(100%);
    white-space: nowrap; /* Ensures the tooltip text doesn't wrap */
    background-color: #ffffff;
    padding: 5px;
    border-radius: 3px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    opacity: 1;
    visibility: hidden; 
    transition: opacity 0.3s, visibility 0.3s;
    z-index: 1000;
    font-size: 0.6em;
  }
  .grid-size-label.game-started:hover #custom-tooltip {
    visibility: visible;
    opacity: 1;
  }

  .howToPlayButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 6px 6px 6px 6px;
  }
  
  .howToPlayButton img {
    width: 35px;
    height: 35px;
  }
  .gridContainer {
    position:relative;
    width:400px;
    height:400px;
    display: grid;
    grid-template-columns: repeat(var(--grid-size), 1fr);
    grid-template-rows: repeat(var(--grid-size), 1fr);
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 0;
    align-items: start;
  }

  .hintOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(37, 34, 34, 0.5); /* White with transparency */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1.5rem;
    text-align: center;
    color: rgb(255, 255, 255); 
    z-index: 1; /* Ensure it sits above the game tiles */
  }
  
  .hintOverlay span {
    padding: 10px;
    font-weight: bold;
    border-radius: 5px;
  }

  
  
  .gridCell {
    height: calc(100% / var(--grid-size)); 
    border: 1px solid #be8b8b;
    background-size: cover;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background-color: var(--cell-color);
    border: 1px solid var(--cell-border-color);
    aspect-ratio: 1/1;
  }
  
  .gridCell.revealed {
    border: 1px solid transparent; 
  }
  
  .score {
    margin: 10px 10px 10px 10px;
  }

  .gameInfo {
    display:flex;
    flex-direction: column;
    align-items:center;
    position: relative;
    
  }

  .logoAndTitle {
    display: flex;
    align-items: center;
    justify-content: center; 
    margin-bottom: 0;
    margin-top:1rem;
    border-bottom: 0;
  }
  .gameInfo h2 {
    margin-top: 0.1rem;
    margin-bottom:0rem; 
    border-bottom: 0;
  }

  .gameInfo h5 {
    margin-bottom: 1rem;
    margin-top:1rem;
    border-top: 0;
   
  }
  .gameInfo img{
    width: 100px;
    height: auto;
  }
  
.gameOver {
    opacity: 0.5;
    pointer-events: none; /* Disables clicking on the grid */
  }
  
  .guessForm {
    margin-top: 20px;
  }
  
  .controls-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px; 
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .finalScore {
    margin-top: 20px;
    font-weight: bold;
  }

  
  button, input[type="text"], select {
    font-size: 1rem;
    font-weight: 900;
    padding: 10px 20px;
    text-emphasis-color: black;
    text-decoration-color: black;
    border-radius: 5px;
    margin:0;
    color:black;
    box-shadow: 0px 0px 5px rgba(212, 103, 103, 0.1);
    
  }
  
  .dropdown {
    position: absolute;
    top: 100%; /* Places it right below the input field */
    left: 0; /* Aligns it with the left side of the input field */
    width: 43%; /* Matches the width of the parent container */
    list-style-type: none;
    padding: 0;
    cursor: pointer;
    margin: 0;
    background-color: #ffffff;
    font-size: 20px;
    border: 3px solid #ddd;
    z-index: 2000;
  }
  .dropdown li{
    padding: 10px; /* Padding inside each list item */
    border-bottom: 3px solid #eee; /* Lighter border for each item */
    background-color: #ffffff; /* Background color for list items */
    font-size: 16px;
  
  }
  
  .dropdown li:hover {
    background-color: #b7b7b7; /* Light grey background color on hover */
  }
  
  button {
    background-color: #FFA500;
    cursor: pointer;
    transition: background-color 0.5s;
    margin:0;
  }

  .submit-button{
    background-color:  #6C8E67;
    color: rgba(250, 250, 250, 1);

  }

  .quit-button{
    background-color: #9E2A2B;
    color:rgba(250, 250, 250, 1);
  }

  

  @keyframes reveal {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .gridCell.revealed {
    animation: reveal 0.5s ease-in-out forwards;
  }

  select {
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #ffab91;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  }
  
  
  /* Add media queries for responsiveness */
  @media (max-width: 768px) {
    .gridContainer {
      width: 80vw; /* Adjust for smaller screens */
      height: 0; /* Adjust for smaller screens */
      padding-bottom: 80vw; /* Bottom padding is the same as the width */
      position: relative; /* Needed for absolutely positioned children */
      overflow: hidden;
      grid-template-columns: repeat(var(--grid-size), 1fr);
    }

    .gridCell {
     
      /* Calculate width, height, top, left based on the number of cells and gridSize */
      width: calc(80vw / var(--grid-size));
      height: calc(80vw / var(--grid-size));
      border: 1px solid #be8b8b;
      background-size: cover;
      cursor: pointer;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      background-color: var(--cell-color);
      border: 1px solid var(--cell-border-color);
      aspect-ratio: 1/1;
      /* Other styles */
    }
    .guessForm {
      margin-top: 20px;
    }

    .controls-container {
      flex-direction: column;
    }

    .controls-container input[type="text"] {
      /* If you want the input field to take the full width */
      width: 100%;
      position:relative;
    }


    .dropdown {
      position: absolute;
      top: 41px; /* Places it right below the input field */
      left: -22px; /* Aligns it with the left side of the input field */
      width: 117%; /* Matches the width of the parent container */
      list-style-type: none;
      padding: 0;
      margin: 0;
      cursor:pointer;
      background-color: #ffffff;
      font-size: 20px;
      border: 3px solid #ddd;
      z-index: 2000;
    }

    

    .dropdown li:first-child{
      border-top: none;
    }

    .dropdown li:hover {
      background-color: #f2f2f2; /* Light grey background color on hover */
    }

    .weeklytheme{
      font-size:large;
      margin-bottom: 10px;
    }
  

  .scoreAndHelpAndGridSizeLabel {
    display: flex;
    width: 80vw;
    justify-content: space-around;
    align-items: center;
    padding: 5px 0; /* Adds padding above and below the items */
   /* Light background for the container */
    border-radius: 5px;
    /*box-shadow: 0 2px 5px rgba(0,0,0,0.2);*/
    margin-bottom: 1px;
  }
  .score {
    font-size: 0.8em; /* Larger text for score */
    margin-right: 20px; /* Spacing to the right of the score */
  }

  .grid-size-label {
    font-size: 0.8em;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2px 2px 2px 2px;
    
  }
  .grid-size-label select {
    font-size: 0.8em;
    padding: 5px 10px; /* Padding inside the select box */
    border-radius: 5px; /* Rounds the corners of the select box */
    cursor: pointer; /* Changes the cursor to indicate it's selectable */
    border-color: #f2f2f2;
  }

  #custom-tooltip {
    left: auto;
    right:10px;
    transform: translateX(20%);
    white-space: nowrap; /* Ensures the tooltip text doesn't wrap */
    background-color: #ffffff;
    padding: 5px;
    border-radius: 3px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    opacity: 1;
    visibility: hidden; 
    transition: opacity 0.3s, visibility 0.3s;
    z-index: 1000;
    font-size: 0.6em;
  }

  .howToPlayButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 6px 6px 6px 6px;
  }
  
  .howToPlayButton img {
    width: 25px;
    height: 25px;
  }

  }
