.modal {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 150%;
  background-color: rgba(0,0,0,0.4);
  align-items: center;
  justify-content: center;
}

.clipboard-message {
  position: absolute; 
  top: 20px; 
  left: 50%;
  transform: translateX(-50%); 
  padding: 10px;
  background-color: #000000; 
  color: #ffffff;
  border-radius: 5px;
  z-index: 30;
  font-size: 1rem;
  
}



.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  font-size: 1rem;
}


.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}



  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContent {
    background-color: white;
    position: relative;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    font-size: 1rem;
  }
  
  .modal-actions button {
    margin: 10px;
    padding: 5px 10px;
  }

  .modal-close-button {
    position: absolute;
    top: 0px; /* Adjust as needed */
    right: 0px; /* Adjust as needed */
    border: none;
    background: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .modal-close-button:hover,
.modal-close-button:focus {
  color: #ff4545; /* Change the color on hover and focus */
  outline: none; /* Remove the default focus outline */
}

/* Add this to your Modal.css */
.social-icons {
  display: flex;
  justify-content: center;
  font-size: 24px;
  margin: 10px  0;
  cursor: pointer;
}

.social-icons .facebook-icon,
.social-icons .x-icon,
.social-icons .whatsapp-icon {
  margin: 0 25px; /* Adjust the margin to add space between icons */
  font-size: 50px;
  cursor: pointer;
}

.facebook-icon {
  color: #1877f2; /* Facebook blue */
}

.x-icon {
  color: #000000; /* Black for X (Twitter) */
}

.whatsapp-icon {
  color: #25d366; /* WhatsApp green */
}

.facebook-icon:hover {
  color: #1558b3; /* Darker Facebook blue */
}

.x-icon:hover {
  color: #333333; /* Darker black for X (Twitter) */
}

.whatsapp-icon:hover {
  color: #1ebea5; /* Darker WhatsApp green */
}

.clipboard-message {
  color: rgb(255, 255, 255);
  margin-top: 10px;
}

@media (max-width: 330px) {
  .modal-content {
    width: 90%;
    font-size: 12px; /* Smaller font size on smaller devices */
  }

  

 /* Add this to your Modal.css */
.social-icons {
  display: flex;
  justify-content: center;
  font-size: 24px;
  margin: 10px  0;
  cursor: pointer;
}

.social-icons .facebook-icon,
.social-icons .x-icon,
.social-icons .whatsapp-icon {
  margin: 0 25px; /* Adjust the margin to add space between icons */
  font-size: 40px;
  cursor: pointer;
}

.facebook-icon {
  color: #1877f2; /* Facebook blue */
}

.x-icon {
  color: #000000; /* Black for X (Twitter) */
}

.whatsapp-icon {
  color: #25d366; /* WhatsApp green */
}

.facebook-icon:hover {
  color: #1558b3; /* Darker Facebook blue */
}

.x-icon:hover {
  color: #333333; /* Darker black for X (Twitter) */
}

.whatsapp-icon:hover {
  color: #1ebea5; /* Darker WhatsApp green */
}

.clipboard-message {
  color: rgb(255, 255, 255);
  margin-top: 10px;
}


}
